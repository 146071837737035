// App Styles
// ----------------------------------------------------------------------------
// Put style rules here that you want to apply to the entire application. These 
// styles are for the entire app and not just one component. Additionally, this 
// file can hold Sass mixins, functions, and placeholder classes to be imported 
// and used throughout the application.

.no-border{
	--inner-border-width: 0;
}
ion-app{
	background-image: url('../assets/imgs/back.jpg');
  background-color: #001638;
  background-position: top right;
  background-repeat: no-repeat;
  font-family: "Open Sans", "Proxima Nova ", "Segoe UI", "Opensans", "Roboto", "Helvetica", -apple-system, system-ui, BlinkMacSystemFont, sans-serif;
	--ion-font-family: "Open Sans", "Proxima Nova ", "Segoe UI", "Opensans", "Roboto", "Helvetica", -apple-system, system-ui, BlinkMacSystemFont, sans-serif;
	--ion-item-border-color: #FFFFFF;
	--highlight-color-focused: #FFF;
}
.split-pane-md{
	--border: none;
}
ion-menu ion-content{
	background: #001638;
}
ion-content, ion-item{
	--background: transparent;
	color: #FFF;
	--color: #FFF;
}
.menu-list{
	background: transparent;
	position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-55%) translateY(-55%);
}
.menu-list ion-item{
	font-size: 0 12px;
	height: 40px;
	--color-activated: #FFF;
  --color-focused: #FFF;
  --color-hover: #FFF;
  --background-activated: transparent;
  --background-focused: transparent;
  --background-hover: transparent;
  --transition: none;
}
.menu-list ion-item ion-icon{
	--color: #999;
}
.menu-list ion-item:hover ion-icon{
	background-color: #FFF;
}

.menu-list ion-icon{
	padding: 6px;
	background-color: #777;
	border-radius: 50%;
	margin: 12px;
	font-size: 16px;
}
.menu-list .activated ion-icon{
	background-color: #FFF;
}
.activated{
	color: #FFF;
	--color: #FFF;
}
.menu-list ion-icon .activated{
	background-color: #FFF;
}
.split-pane-md.split-pane-visible>.split-pane-side{
	max-width: 300px;
}
.page-content{
	display: table;
	background: rgba(0, 0, 0, .2);
  color: #FFF;
}
.page-title{
	font-size: 4em;
}

@media (max-width: 576px) {
}

@media (min-width: 576px) {
}

@media (max-width: 992px) {
  .page-content{
  	padding: 20px;
	}
}

@media (min-width: 820px) {
	.page-content{
		position: absolute;
	  top: 50%;
	  transform: translateY(-55%);	
	  max-width: 800px;
	}
}


